<template>
  <div>
    <p class="mb-0">
      At a temperature of
      <stemble-latex content="$0^o\text{C,}$" />
      <stemble-latex content="$\text{K}_{\text{w}}$" />
      is equal to
      <stemble-latex content="$1.14\times10^{-15}.$" />
      Determine the nature of a solution with a
      <stemble-latex content="$\text{pH=7}$" />
      at
      <stemble-latex content="$0^o\text{C}.$" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question170j',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: '$\\text{Acidic}$', value: 'acidic'},
        {expression: '$\\text{Neutral}$', value: 'neutral'},
        {expression: '$\\text{Basic}$', value: 'basic'},
        {
          expression: '$\\text{Insufficient Information}$',
          value: 'needMoreInfo',
        },
      ],
    };
  },
};
</script>
